import React from "react";
import { generateLinkComponent } from "tuteria-frontend-components/src/simple/Button";
import { Link, Redirect } from "react-router-dom";
import { ExamLandingPage } from "tuteria-frontend-components/src/pages/ExternalPages/ClientRequestLandingPage";
import {
  IELTSContent,
  GMATContent,
  GREContent
} from "tuteria-frontend-components/src/pages/ExternalPages/ClientRequestLandingPage/PageContent";
import { Icon } from "tuteria-frontend-components/src/simple";
const PrimaryLinkButton = generateLinkComponent(Link, "primary");
// const SecondaryLinkButton = getLinkButton("secondary");
// const  = getLinkButton("primary");
const video_link =
  process.env.video_link ||
  "https://www.youtube.com/embed/mla_SMQoFeU?rel=0&amp;controls=0&amp;modestbranding=1&amp;fs=0&amp;disablekb=1&amp;showinfo=0";
export const ExamPages = ({ examname, ...props }) => {
  let exams = {
    ielts: IELTSContent,
    gmat: GMATContent,
    gre: GREContent
  };
  let value = exams[examname];
  if (Boolean(value)) {
    return (
      <ExamLandingPage
        videoLink={video_link}
        {...props}
        content={value}
        linkRender={(text, props = { big: true }) => (
          <PrimaryLinkButton
            className="exam-page-button"
            {...props}
            to={`/s/${examname}-tutors/about-exam`}
          >
            {text} <Icon name="arrow-right" />
          </PrimaryLinkButton>
        )}
        buttonText="Get a tutor"
      />
    );
  }
  return <Redirect to="/" />;
};

export default ExamPages;
